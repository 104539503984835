import React from "react";

import settings from "@styles/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import theme from "@styles/theme";

import Drawer from "./components/Drawer";
import AppBar from "./components/AppBar";
import styles from "@styles/styles";

const permanentDrawerBreakpointPx = theme.breakpoints.values.sm;

interface Props {
  children?: React.ReactChild | React.ReactChild[];
  disablePermanentDrawer?: boolean;
  appBarChildrenWithoutPermanentDrawer?: React.ReactChild | React.ReactChild[];
  appBarChildrenWithPermanentDrawer?: React.ReactChild | React.ReactChild[];
}

export default function Navigation(props: Props) {
  const largeViewPort = useMediaQuery(`(min-width:${permanentDrawerBreakpointPx}px)`);

  if (props.disablePermanentDrawer === true) {
  }

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (props.disablePermanentDrawer === true) {
    return (
      <div>
        <AppBar
          permanentDrawer={false}
          handleDrawerToggle={handleDrawerToggle}
          childrenOnLargeScreen={props.appBarChildrenWithPermanentDrawer}
          childrenOnSmallScreen={props.appBarChildrenWithoutPermanentDrawer}
        />
        <nav>
          <Drawer
            handleDrawerToggle={handleDrawerToggle}
            drawerOpen={drawerOpen}
            permanent={false}
          />
        </nav>
        <main>
          <div
            style={{
              paddingTop: settings.navBarHeight,
              paddingLeft: 0,
            }}
          >
            {props.children}
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <AppBar
          permanentDrawer={largeViewPort ? true : false}
          handleDrawerToggle={handleDrawerToggle}
          childrenOnLargeScreen={props.appBarChildrenWithPermanentDrawer}
          childrenOnSmallScreen={props.appBarChildrenWithoutPermanentDrawer}
        />
        <nav>
          <Drawer
            handleDrawerToggle={handleDrawerToggle}
            drawerOpen={drawerOpen}
            permanent={largeViewPort ? true : false}
          />
        </nav>
        <main>
          <div
            style={{
              paddingTop: settings.navBarHeight,
              paddingLeft: largeViewPort ? styles.drawerWidth : 0,
            }}
          >
            {props.children}
          </div>
        </main>
      </div>
    );
  }
}
