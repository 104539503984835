import { createMuiTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#64bf96",
      main: "#3eaf7c",
      dark: "#2b7a56",
      contrastText: "#fff",
    },
    secondary: {
      light: "#42525f",
      main: "#132738",
      dark: "#0d1b27",
      contrastText: "#fff",
    },
    text: {
      primary: "#514947",
    },
  },
  typography: {
    h1: {
      fontSize: "3.33rem",
      fontWeight: "bold"
    },
    h2: {
      fontSize: "2.33rem",
      fontWeight: "bold"
    },
    h3: {
      fontSize: "1.8rem",
      fontWeight: "bold"
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold"
    },
    h5: {
      fontSize: "1.6rem",
      fontWeight: "bold"
    },
    h6: {
      fontSize: "1.6rem",
      fontWeight: "bold"
    },
    subtitle1: { fontSize: 14, lineHeight: 1.5 },
    body1: { fontSize: 14 },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme.overrides = {
  MuiCssBaseline: {
    "@global": {
      html: {
        height: "100%",
        width: "100%",
        margin: "0",
        padding: "0",
      },
      body: {
        height: "100%",
        width: "100%",
        margin: "0",
        padding: "0",
      },
    },
  },
  MuiTypography: {
    h1: {
      color: theme.palette.primary.main,
    },
    h2: {
      color: theme.palette.primary.main,
    },
    h3: {
      color: theme.palette.primary.main,
    },
    h4: {
      color: theme.palette.primary.main,
    },
    h5: {
      color: theme.palette.primary.light,
    },
    h6: {
      color: theme.palette.primary.light,
    },
    subtitle1: {
      color: theme.palette.grey[700],
    },
  },

};

export default responsiveFontSizes(theme);
// export default theme;
