import React, { useContext, useState } from "react";
import { Drawer, DrawerProps } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import Link from "next/link";
import styles from "@styles/styles";
import Image from "next/image";
import DividerTitle from "./DividerTitle";
import LinkToPage from "@components/navigation/components/LinkToPage";
import theme from "@styles/theme";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      width: styles.drawerWidth,
      overflowX: "hidden",
    },
  })
);

const MenuEmoji = (props: { emoji: string }) => {
  return (
    <div style={{ fontSize: 20, paddingRight: theme.spacing(3), textAlign: "center" }}>
      {props.emoji}
    </div>
  );
};

const logo = (
  <Link href="/">
    <a style={{ height: "100%", display: "flex", alignItems: "center" }}>
      <Image width={157} height={40} src="/images/logo.svg" />
    </a>
  </Link>
);

export default function Component(props: {
  handleDrawerToggle: any;
  permanent: boolean;
  drawerOpen: boolean;
}) {
  const classes = useStyles();

  const nonPermanentDrawerProps: DrawerProps = {
    variant: "temporary",
    anchor: theme.direction === "rtl" ? "right" : "left",
    open: props.drawerOpen,
    onClose: props.handleDrawerToggle,
    onClick: props.handleDrawerToggle,
    ModalProps: {
      keepMounted: true, // Better open performance on mobile.
    },
  };

  const permanentDrawerProps: DrawerProps = {
    open: true,
    variant: "permanent",
  };

  const drawerProps = props.permanent ? permanentDrawerProps : nonPermanentDrawerProps;

  return (
    <Drawer
      {...drawerProps}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        role="presentation"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          width: styles.drawerWidth,
          flexShrink: 0,
        }}
      >
        <div>
          <div
            style={{
              height: styles.navBarHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: theme.spacing(2),
            }}
          >
            {logo}
            {!props.permanent && (
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
                edge="start"
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>

          <List style={{ padding: 0 }}>
            <Divider />

            <DividerTitle title={"Getting Started"} />
            <LinkToPage url="/getting-started/quickstart">
              <ListItem button component="a" key={"Quickstart"}>
                <MenuEmoji emoji="🚀" />
                <ListItemText primary={"Quickstart"} />
              </ListItem>
            </LinkToPage>

            <LinkToPage url="/api-playground">
              <ListItem button component="a" key={"API playground"}>
                <MenuEmoji emoji="▶️" />
                <ListItemText primary={"API playground"} />
              </ListItem>
            </LinkToPage>

            <DividerTitle title={"Documentation"} />
            <LinkToPage url="/documentation/api">
              {" "}
              <ListItem button key={"API options"}>
                <MenuEmoji emoji="⚙️" />
                <ListItemText primary={"API options"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/documentation/charts">
              <ListItem button key={"Charts"}>
                <MenuEmoji emoji="📈" />
                <ListItemText primary={"Charts"} />
              </ListItem>
            </LinkToPage>
            <DividerTitle title={"Contact"} />
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Request an API key"}>
                <MenuEmoji emoji="🔑" />
                <ListItemText primary={"Request an API key"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Pricing"}>
                <MenuEmoji emoji="🏷" />
                <ListItemText primary={"Pricing"} />
              </ListItem>
            </LinkToPage>
            <LinkToPage url="/get-in-touch">
              <ListItem button key={"Get in touch"}>
                <MenuEmoji emoji="ℹ️" />
                <ListItemText primary={"Get in touch"} />
              </ListItem>
            </LinkToPage>
          </List>
        </div>

        <List style={{ padding: 0 }}>
          <Divider style={{ marginBottom: theme.spacing(1) }} />
          <ListItem button key={"Cookies"}>
            <MenuEmoji emoji="🍪" />
            <ListItemText primary={"Cookies"} />
          </ListItem>
          <LinkToPage url="https://www.aeco.cloud" openNewTab={true}>
            <ListItem button key={"Powered by Aeco"}>
              <MenuEmoji emoji="⚡️" />
              <ListItemText primary={"Powered by Aeco"} />
            </ListItem>
          </LinkToPage>
        </List>
      </div>
    </Drawer>
  );
}
